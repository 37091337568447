import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 11" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 11</h1>
      <p>Fredag, at last!</p>
      <p>Husk å ta vare på hendende dine, mamma Litjen! I helga skal vi kose oss, og jeg er klar for mange etapper med dere Litjeners. Det blir spennende!</p>
      <p>Hilsen Litjen</p>
      <p>PS! I dag kan vi synge sangen fra forrige fredag om du vil. Jeg er jo så glad hver fredagskveld!</p>
    </div>
  </Layout>
)

export default LitjenLuke
